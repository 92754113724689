@use '@angular/material' as mat;
@use 'variables' as vars;
@use 'media-queries' as mediaQueries;

@include mat.elevation-classes();
@include mat.app-background();

// begin globa themes
$scoot-prmary-palette: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  // color used as default button background, for example
  500: vars.$navy,
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    // color used as default button text, for example
    300: vars.$white,
    // ... continues to 900,,,
  ),
);
$scoot-primary: mat.m2-define-palette($scoot-prmary-palette);
$scoot-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$scoot-warn: mat.m2-define-palette(mat.$m2-red-palette);

$scoot-typography: mat.m2-define-typography-config(
  $font-family: 'Europa-Regular',
);

$scoot-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $scoot-primary,
      accent: $scoot-accent,
      warn: $scoot-warn,
    ),
    typography: $scoot-typography,
  )
);

@include mat.all-component-themes($scoot-theme);
// end global themes

.mdc-linear-progress__bar-inner {
  border-color: vars.$ocean !important;
}

.mat-mdc-snack-bar-container {
  padding: 0 !important;
  border-radius: 10px !important;
  background-color: vars.$white !important;
}

.mat-dialog-custom-container {
  .mat-mdc-dialog-title {
    font-family: vars.$font-medium;
    color: vars.$grey-33;
    letter-spacing: 0.25px;
    margin-bottom: 14px;
    font-size: 20px;
  }
  .mat-mdc-dialog-surface {
    border-radius: 16px;
    padding: 16px;
  }
  .mat-mdc-dialog-actions {
    button {
      font-family: vars.$font-bold;
      letter-spacing: 0.25px;
      color: vars.$grey-33;
    }
  }
}

.custom-toggle {
  --mdc-switch-selected-handle-color: vars.$white !important;
  --mdc-switch-selected-focus-state-layer-color: vars.$white !important;
  --mdc-switch-selected-handle-color: vars.$white !important;
  --mdc-switch-selected-hover-state-layer-color: vars.$white !important;
  --mdc-switch-selected-pressed-state-layer-color: vars.$white !important;
  --mdc-switch-selected-focus-handle-color: vars.$white !important;
  --mdc-switch-selected-hover-handle-color: vars.$white !important;
  --mdc-switch-selected-pressed-handle-color: vars.$white !important;
  --mdc-switch-selected-focus-track-color: #333333 !important;
  --mdc-switch-selected-hover-track-color: #333333 !important;
  --mdc-switch-selected-pressed-track-color: #333333 !important;
  --mdc-switch-selected-track-color: #333333 !important;
  --mdc-switch-unselected-focus-handle-color: vars.$white !important;
  --mdc-switch-unselected-focus-state-layer-color: vars.$white !important;
  --mdc-switch-unselected-focus-track-color: rgba(51, 51, 51, 0.1) !important;
  --mdc-switch-unselected-handle-color: vars.$white !important;
  --mdc-switch-unselected-hover-handle-color: vars.$white !important;
  --mdc-switch-unselected-hover-state-layer-color: vars.$white !important;
  --mdc-switch-unselected-hover-track-color: rgba(51, 51, 51, 0.1) !important;
  --mdc-switch-unselected-icon-color: vars.$white !important;
  --mdc-switch-unselected-pressed-handle-color: vars.$white !important;
  --mdc-switch-unselected-pressed-state-layer-color: vars.$white !important;
  --mdc-switch-unselected-pressed-track-color: rgba(51, 51, 51, 0.1) !important;
  --mdc-switch-unselected-track-color: rgba(51, 51, 51, 0.1) !important;

  .mdc-switch {
    margin-bottom: 0.5rem !important;
    margin-right: 8px !important;

    .mdc-switch__track::after {
      background: vars.$grey-33 !important;
    }
  }
}

.custom-toggle-scootaverse {
  --mdc-switch-selected-handle-color: vars.$white !important;
  --mdc-switch-selected-focus-state-layer-color: vars.$white !important;
  --mdc-switch-selected-handle-color: vars.$white !important;
  --mdc-switch-selected-hover-state-layer-color: vars.$white !important;
  --mdc-switch-selected-pressed-state-layer-color: vars.$white !important;
  --mdc-switch-selected-focus-handle-color: vars.$white !important;
  --mdc-switch-selected-hover-handle-color: vars.$white !important;
  --mdc-switch-selected-pressed-handle-color: vars.$white !important;
  --mdc-switch-selected-focus-track-color: vars.$ocean !important;
  --mdc-switch-selected-hover-track-color: vars.$ocean !important;
  --mdc-switch-selected-pressed-track-color: vars.$ocean !important;
  --mdc-switch-selected-track-color: vars.$ocean !important;
  --mdc-switch-unselected-focus-handle-color: vars.$white !important;
  --mdc-switch-unselected-focus-state-layer-color: vars.$white !important;
  --mdc-switch-unselected-focus-track-color: rgba(51, 51, 51, 0.1) !important;
  --mdc-switch-unselected-handle-color: vars.$white !important;
  --mdc-switch-unselected-hover-handle-color: vars.$white !important;
  --mdc-switch-unselected-hover-state-layer-color: vars.$white !important;
  --mdc-switch-unselected-hover-track-color: rgba(51, 51, 51, 0.1) !important;
  --mdc-switch-unselected-icon-color: vars.$white !important;
  --mdc-switch-unselected-pressed-handle-color: vars.$white !important;
  --mdc-switch-unselected-pressed-state-layer-color: vars.$white !important;
  --mdc-switch-unselected-pressed-track-color: rgba(51, 51, 51, 0.1) !important;
  --mdc-switch-unselected-track-color: rgba(51, 51, 51, 0.1) !important;

  .mdc-switch {
    margin-right: 8px !important;

    .mdc-switch__track::after {
      background: vars.$ocean !important;
    }
  }
}

.survey-modal {
  background: linear-gradient(0deg, vars.$grey-fb, vars.$grey-fb), vars.$white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15308);
  border-radius: 16px;

  .mat-mdc-dialog-surface {
    padding: 0px !important;
  }

  .mat-mdc-dialog-container {
    position: relative;
    padding: 0;
    border: 3px;
    border-radius: 16px;
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding-bottom: 24px;
  }
}

.photo-booth-viewer {
  .mat-mdc-dialog-surface {
    padding: 0px !important;
  }
}

.no-padding-social-modal {
  .mat-mdc-dialog-surface {
    padding: 0px !important;
  }

  .mat-mdc-dialog-container {
    position: relative;
    padding: 0;
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 24px !important;
  }
}

.no-padding-social-page-modal {
  .mat-mdc-dialog-surface {
    padding: 0px !important;
    overflow: hidden;
    background-color: transparent !important;
  }

  .mdc-label {
    font-family: vars.$font-primary !important;
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 0 40px 10px !important;
  }

  .mat-mdc-dialog-title {
    margin: 0 0 20px !important;
  }

  .mdc-dialog__title::before {
    height: auto;
  }

  .mat-mdc-tab-group {
    .mdc-tab {
      color: vars.$grey-33 !important;
      background-color: transparent !important;
      opacity: 0.6 !important;
    }

    .mdc-tab__text-label {
      color: vars.$grey-33 !important;
      letter-spacing: normal;
      text-transform: uppercase;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-color: vars.$grey-33;
    }

    ngb-carousel {
      .carousel-inner .carousel-item {
        .visually-hidden {
          display: none;
        }

        .theme-label {
          color: vars.$black;
        }
      }

      .carousel-control-prev,
      .carousel-control-next {
        width: 48px;
        justify-content: center !important;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;

        .visually-hidden {
          display: none;
        }
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        background-size: 25px 25px;

        .carousel-control-prev-icon {
          background-image: url('../assets/images/carousel-left.png') !important;
        }

        .carousel-control-next-icon {
          background-image: url('../assets/images/carousel-right.png') !important;
        }
      }
    }
  }
}

.social-modal {
  .mat-mdc-dialog-container {
    position: relative;
    padding: 0;
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 24px !important;
  }
}

.mat-mdc-dialog-surface {
  border-radius: 8px !important;
  padding: 25px;
}

.error-modal .mat-mdc-dialog-container {
  padding: 10px;
  overflow: hidden;
}

.presentation_toolbar_v1 {
  mat-slider {
    height: 20px !important;
    min-width: 130px !important;
    margin-left: 0;
    margin-right: 0;

    .mdc-slider__track {
      height: 2px !important;

      .mdc-slider__track--inactive {
        background-color: #000042 !important;
      }

      .mdc-slider__track--active .mdc-slider__track--active_fill {
        border-color: vars.$white !important;
      }

      .mdc-slider__track--active,
      .mdc-slider__track--inactive {
        height: 2px !important;
      }
    }

    .mdc-slider__thumb {
      height: 20px;

      .mdc-slider__thumb-knob {
        border-color: vars.$white !important;
        transform: translate(-50%, -50%) scale(0.7);
      }

      .mat-mdc-focus-indicator .mat-mdc-slider-focus-ripple,
      .mat-mdc-focus-indicator .mat-mdc-slider-active-ripple {
        display: none;
      }
    }
  }

  @include mediaQueries.breakpoint(tablet) {
    mat-slider {
      .mdc-slider__thumb {
        height: 12px !important;
      }
    }
  }

  @include mediaQueries.breakpoint(tablet-small) {
    mat-slider {
      height: 15px !important;
      min-width: 90px !important;
      margin-left: 0;
      margin-right: 0;
      width: 90% !important;

      .mdc-slider__track {
        height: 1.5px !important;

        .mdc-slider__track--active,
        .mdc-slider__track--inactive {
          height: 1.5px !important;
        }
      }

      .mdc-slider__thumb {
        .mdc-slider__thumb-knob {
          transform: translate(-50%, -50%) scale(0.5);
        }
      }
    }
  }
}

.social-settings-slider {
  mat-slider {
    height: 20px !important;
    min-width: 130px !important;
    margin-left: 0;
    margin-right: 0;

    .mdc-slider__track {
      height: 4px !important;

      .mdc-slider__track--inactive {
        background-color: black !important;
        opacity: 0.24 !important;
      }

      .mdc-slider__track--active .mdc-slider__track--active_fill {
        border-color: #ffd740 !important;
      }

      .mdc-slider__track--active,
      .mdc-slider__track--inactive {
        height: 4px !important;
      }
    }

    .mdc-slider__thumb {
      height: 20px;

      .mdc-slider__thumb-knob {
        border-color: #ffd740 !important;
        transform: translate(-50%, -50%) scale(0.7);
      }

      .mat-mdc-focus-indicator .mat-mdc-slider-focus-ripple,
      .mat-mdc-focus-indicator .mat-mdc-slider-active-ripple {
        display: none;
      }
    }
  }
}

.duplicate-modal {
  min-width: 345px !important;

  .mat-mdc-dialog-surface {
    padding: 5px !important;
  }
}

.sign-input-v1 {
  width: 100%;

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
    padding: 1em 0.5em !important;
    margin-top: 0 !important;
    border-top: none;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 1em;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline-thick {
    color: vars.$navy !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-infix
    .mat-form-field-label-wrapper
    .mat-form-field-appearance-outline
    .mat-form-field-label {
    margin-top: 15px !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-label {
    margin-top: 5px !important;
  }
}

.tel-input-wrapper {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 3px !important;
    margin: 0.15em 0 !important;
  }
}

.event-form {
  &.mat-mdc-form-field {
    width: 100% !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    .mat-mdc-form-field-infix {
      display: flex !important;
      width: 320px !important;
    }
  }
}

.badging-form {
  margin-bottom: 8px;
  width: 100% !important;
  .event-form {
    width: 100% !important;

    .mat-mdc-text-field-wrapper {
      width: 100% !important;
      margin: 0 !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-error-wrapper {
        line-height: 1px !important;
      }
    }

    .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex
      .mdc-notched-outline
      .mdc-notched-outline__notch
      .mdc-floating-label {
      padding: 0;
      font-family: vars.$font-primary !important;
      font-size: 14px !important;

      .mat-mdc-form-field-required-marker {
        display: none;
      }

      mat-label {
        margin-top: 0;
      }
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        width: 100% !important;
        font-family: vars.$font-medium;

        textarea {
          height: 100px;
          resize: vertical;
          min-height: 18px;
          overflow-y: hidden;
          font-size: 14px;
          color: vars.$grey-33;
        }

        input {
          font-size: 14px;
          color: vars.$grey-33;
        }

        .mat-mdc-form-field-hint {
          font-size: 12px;
        }

        textarea::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

.badge-option {
  mat-pseudo-checkbox {
    display: block !important;
  }
}

.search-participants-field {
  .mat-mdc-text-field-wrapper {
    margin: 0.05em 0 !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    height: 56px !important;
    padding: 0;
    display: flex;
    align-items: center;

    input {
      margin-left: 7px !important;
      width: 80%;
    }
  }

  .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mdc-notched-outline
    .mdc-notched-outline__notch
    .mdc-floating-label {
    margin-left: 30px;
    padding: 0;

    font-family: vars.$font-primary !important;
    font-size: 14px !important;

    mat-label {
      margin-top: 0;
    }
  }
}

.outside-label {
  .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mdc-notched-outline
    .mdc-notched-outline__notch
    .mdc-floating-label {
    margin-left: 0;

    mat-label {
      margin-top: -0.25em !important;
    }
  }
}

.mat-mdc-tooltip-panel {
  .mat-mdc-tooltip {
    max-width: 280px;
  }
}

.badge-action-tooltip {
  width: max-content !important;
}

.guest-card-v1 {
  .has-error {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
      color: vars.$error !important;
      opacity: 0.7 !important;
    }
  }
}

.moderated-chat {
  .mat-mdc-tab-header {
    border-bottom: none;
    height: 30px !important;
  }

  .mdc-tab--active:before,
  .mdc-tab--active:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 16px;
    width: 16px;
    display: block;
    background-color: transparent;
    border-top: 0;
  }

  .mdc-tab--active:before {
    right: 100%;
    border-radius: 0 0 50% 0;
    border-left: 0;
    top: auto;
    left: auto;
    margin: 0;
    box-shadow: 5px 5px 0 0 #f2f2f2;
  }

  .mdc-tab--active:after {
    left: 100%;
    border-radius: 0 0 0 50%;
    border-right: 0;
    margin: 0;
    box-shadow: -5px 5px 0 0 #f2f2f2;
  }

  .mdc-tab--active {
    position: relative;
    overflow: unset;
    font-family: vars.$font-bold !important;
    font-weight: 700 !important;
    background-color: #f2f2f2 !important;

    border-radius: 10px 10px 0 0;
    border-bottom: none !important;
    &:first-child {
      margin-left: 2px;
      &:before {
        display: none;
      }
    }

    .mdc-tab__text-label {
      color: vars.$black !important;
    }

    .mdc-tab-indicator {
      display: none;
    }
  }

  .mat-mdc-tab-body-wrapper {
    background-color: transparent;
    display: none;
  }

  .mdc-tab {
    color: vars.$black !important;
    font-size: 10px !important;
    line-height: 18px !important;
    letter-spacing: 0.3px !important;
    opacity: 1;
    min-width: unset !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: 30px !important;
  }
}

//TODO: remove when Material is themed
.badge-tab {
  .mat-mdc-tab-header {
    border-bottom: none;
  }

  .mat-mdc-tab-list {
    box-shadow: inset 0 -1px 0 0 vars.$border;
  }

  .mdc-tab--active:before,
  .mdc-tab--active:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 16px;
    width: 16px;
    display: block;
    background-color: transparent;
    border: solid 1px vars.$border;
    border-top: 0;
  }

  .mdc-tab--active:before {
    right: 100%;
    border-radius: 0 0 50% 0;
    border-left: 0;
    top: auto;
    left: auto;
    margin: 0;
    box-shadow: 5px 5px 0 0 vars.$white;
  }

  .mdc-tab--active:after {
    left: 100%;
    border-radius: 0 0 0 50%;
    border-right: 0;
    margin: 0;
    box-shadow: -5px 5px 0 0 vars.$white;
  }

  .mdc-tab--active {
    position: relative;
    overflow: unset;
    font-family: vars.$font-bold !important;
    font-weight: 700 !important;
    background-color: vars.$white !important;

    border: 1px solid vars.$border !important;
    border-radius: 10px 10px 0 0;
    border-bottom: none !important;
    &:first-child {
      margin-left: 2px;
      &:before {
        display: none;
      }
    }

    .mdc-tab__text-label {
      color: vars.$black !important;
    }

    .mdc-tab-indicator {
      display: none;
    }
  }

  .mat-mdc-tab-body-wrapper {
    background-color: vars.$white;
  }

  .mat-mdc-tab-group {
    margin-top: -1px;
  }

  .mdc-tab {
    font-family: vars.$font-medium;
    font-weight: 400;
    color: vars.$navy !important;
    font-size: 12px !important;
    line-height: 18px !important;
    letter-spacing: 0.3px !important;
    opacity: 1;
    width: 75px !important;
    min-width: unset !important;
    height: 35px !important;
  }

  .mat-mdc-tab-body-content {
    padding: 15px 20px 15px 20px;
  }
}

mat-form-field {
  .mdc-notched-outline__notch {
    border-left: none !important;
  }

  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__trailing,
  .mdc-text-field--disabled .mdc-notched-outline__notch {
    opacity: 0.4 !important;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch {
    border-color: vars.$input-grey !important;
    border-width: 1.5px !important;
    opacity: 0.9;
  }

  .mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: vars.$navy !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: vars.$navy !important;
  }
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background: rgba(0, 0, 0, 0.12) !important;
  }
}

.mat-mdc-select-panel .mdc-list-item--selected .mdc-list-item__primary-text {
  color: vars.$navy !important;
}

mat-progress-bar {
  .mdc-linear-progress__buffer .mdc-linear-progress__buffer-bar {
    background-color: transparent;
  }

  .mdc-linear-progress__bar .mdc-linear-progress__bar-inner {
    border-color: vars.$white;
  }
}

.events-recordings,
.admin-recordings,
.users-tabs,
.background-tabs {
  .mat-mdc-tab-group {
    .mdc-tab {
      color: vars.$grey-33 !important;
      background-color: transparent !important;
      opacity: 0.6 !important;
    }

    .mdc-tab__text-label {
      color: vars.$grey-33 !important;
      letter-spacing: normal;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-color: vars.$grey-33 !important;
    }

    .mdc-tab--active:focus .mdc-tab-indicator__content--underline {
      border-color: vars.$grey-33 !important;
    }
  }
}

.background-tabs {
  .mat-mdc-tab-group {
    .mdc-tab__text-label {
      text-transform: uppercase !important;
      font-size: 12px !important;
    }
  }

  &.presentation-v2 {
    .mat-mdc-tab-group {
      .mdc-tab__text-label {
        text-transform: capitalize !important;
      }
    }
  }
}

.events-recordings.layout-v2,
.admin-recordings.layout-v2,
.users-tabs.layout-v2 {
  .mat-mdc-tab-group {
    .mdc-tab--active {
      opacity: 1 !important;
    }

    .mdc-tab--active .mdc-tab__text-label {
      color: vars.$navy !important;
      letter-spacing: normal;
      font-weight: 700 !important;
      font-size: 16px !important;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-color: vars.$ocean !important;
    }

    .mdc-tab--active:focus .mdc-tab-indicator__content--underline {
      border-color: vars.$ocean !important;
    }
  }
}

.ag-theme-material {
  width: 100%;
  height: 100%;

  .ag-header {
    border: none;
    height: 57px !important;
    min-height: 57px !important;
    display: flex;
    align-items: center;

    .ag-header-row {
      height: 100% !important;

      .ag-header-cell {
        height: 100% !important;
      }
    }
  }
  .ag-row {
    border: none;
    &:nth-child(odd) {
      background-color: vars.$white_f5;
    }
    &:hover {
      background-color: vars.$grey-f9;
    }
  }
  .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border: none;
  }
  .ag-cell {
    text-align: left;
    font-family: vars.$font-primary;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .ag-header-cell {
    font-family: vars.$font-bold;
    text-transform: uppercase;

    .ag-header-select-all {
      z-index: 100 !important;
    }
  }

  .ag-overlay-no-rows-center {
    font-family: vars.$font-primary;
    font-family: vars.$font-bold;
    text-transform: uppercase;
    padding-top: 20px;
  }
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #091d50ce !important;
}

mat-form-field {
  // Material Input background and focused Color
  --mdc-filled-text-field-container-color: transparent;
  --mat-form-field-focus-state-layer-opacity: 0;

  // Material Input Line and label color
  --mdc-filled-text-field-caret-color: #091d50;
  --mdc-filled-text-field-focus-active-indicator-color: #091d50;
  --mdc-filled-text-field-focus-label-text-color: #091d50ce;
}

mat-checkbox {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0c58c6;
  --mdc-checkbox-selected-hover-icon-color: #0c58c6;
  --mdc-checkbox-selected-icon-color: #0c58c6;
  --mdc-checkbox-selected-pressed-icon-color: #0c58c6;
  --mdc-checkbox-selected-focus-state-layer-color: #0c58c6;
  --mdc-checkbox-selected-hover-state-layer-color: #0c58c6;
  --mdc-checkbox-selected-pressed-state-layer-color: #0c58c6;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

mat-tab-header {
  // Material Tabs Colors
  --mdc-tab-indicator-active-indicator-color: #0c58c6;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0c58c6;
  --mat-tab-header-active-ripple-color: #0c58c6;
  --mat-tab-header-inactive-ripple-color: #0c58c6;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0c58c6;
  --mat-tab-header-active-hover-label-text-color: #0c58c6;
  --mat-tab-header-active-focus-indicator-color: #0c58c6;
  --mat-tab-header-active-hover-indicator-color: #0c58c6;
}

.custom-radio {
  --mdc-radio-disabled-selected-icon-color: #000 !important;
  --mdc-radio-disabled-unselected-icon-color: #000 !important;
  --mdc-radio-unselected-hover-icon-color: #212121 !important;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54) !important;
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54) !important;
  --mdc-radio-selected-focus-icon-color: #333 !important;
  --mdc-radio-selected-hover-icon-color: #333 !important;
  --mdc-radio-selected-icon-color: #333 !important;
  --mdc-radio-selected-pressed-icon-color: #333 !important;
  --mat-radio-ripple-color: #000 !important;
  --mat-radio-checked-ripple-color: #333 !important;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38) !important;
}

.custom-toggle-button {
  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        mat-pseudo-checkbox {
          display: none !important;
        }
      }
    }
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: #eef0fe !important;
  }
}

.social-guest-modal .mat-mdc-dialog-container {
  padding: 0;
  overflow: hidden;
  position: relative;
  .modal-text {
    padding: 20px 40px;
    text-align: center;
    white-space: pre-line;
  }
}

.legend-dot {
  background: var(--color);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow:
    0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 8px 12px 0px rgba(9, 30, 66, 0.15);
  border: 2px solid vars.$white;
}

.visual-indicator-select {
  .dropdown-trigger {
    padding: 0 5px 0 16px;
    background: rgba(255, 255, 255, 0.7);
    border: 1.5px solid vars.$input-grey;
    border-radius: 10px !important;
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);

    .mdc-button__label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: vars.$grey-7d;
    }

    .right-accessory {
      display: none;
    }
  }
}

.visual-indicator-options {
  border-radius: 10px !important;
  background: rgba(255, 255, 255, 0.7);
  border: 1.5px solid vars.$input-grey;
  padding: 0 5px 0 16px;

  .option-button {
    .mdc-button__label {
      width: 100%;
      justify-content: flex-start;
      color: vars.$grey-7d;
      display: flex;
    }
  }
}

.custom-auto-complete-colors {
  --mat-autocomplete-background-color: #091d50 !important;
  --mat-option-label-text-color: white !important;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #091d50;
  --mat-option-selected-state-label-text-color: white !important;

  .mat-mdc-option {
    color: white !important;
  }

  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    color: #091d50;
  }
}

.custom-snackbar {
  .mdc-snackbar__surface {
    padding-right: 0px !important;
  }
}

.custom-snackbar-group {
  .mdc-snackbar__surface {
    padding-right: 0px !important;
  }
  position: absolute;
  right: 39%;
  bottom: 64px;
}

.custom-snackbar-group-mobile {
  .mdc-snackbar__surface {
    padding-right: 0px !important;
  }
  position: absolute;
  top: 60px;
}

.scootaverse-snackbar {
  .mdc-snackbar__surface {
    background-color: vars.$black !important;
    padding: 0;
  }

  mat-icon {
    width: 25px;
    height: 30px;
  }
}

.host-social-modal {
  .customize-section {
    .details-area {
      mat-form-field {
        .mat-mdc-form-field-subscript-wrapper {
          display: none;
        }
      }
    }
  }
}

.nav-items-menu {
  position: absolute !important;
  top: 8px !important;
  padding: 10px 9px;
  gap: 10px;
}

.ag-theme-material.clear-theme {
  .ag-overlay-no-rows-wrapper {
    padding-top: 3em !important;
  }

  .ag-layout-normal {
    min-height: 50px !important;
  }

  .ag-header {
    border: none;
    height: 57px !important;
    min-height: 57px !important;
    display: flex;
    align-items: center;
    text-transform: capitalize !important;
    color: vars.$black !important;
    border: 1px solid vars.$border !important;

    .ag-header-row {
      height: 100% !important;

      .ag-header-cell {
        height: 100% !important;
        color: vars.$black !important;
        text-transform: capitalize !important;
      }
    }
  }

  .ag-row {
    border: 1px solid vars.$border !important;
    background-color: vars.$white !important;

    &:hover {
      background-color: vars.$grey-f9;
    }
  }

  .ag-paging-panel {
    height: 30px !important;
    display: flex;
    justify-content: center;
    border: none;
    margin-top: 15px !important;
  }

  .ag-picker-field-wrapper {
    min-height: 15px !important;
    height: 15px !important;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: 100% !important;
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .ag-sticky-bottom {
    display: none !important;
  }
}

.ag-theme-material.padding-theme {
  .ag-header {
    border: none !important;
  }

  .ag-row {
    border-left: none !important;
    border-right: none !important;
  }

  .ag-body {
    margin-bottom: 15px;
  }

  .ag-row {
    padding: 5px;

    &:hover {
      background-color: vars.$white !important;
    }
  }
}

.ag-theme-material.custom-ag-table {
  .ag-row {
    padding: 3px !important;

    .ag-cell {
      profile-picture {
        .avatar-header {
          width: 42px !important;
          height: 42px !important;
          margin-top: -10px;
        }
      }
    }

    &:hover {
      background-color: vars.$white !important;
    }
  }

  .ag-tooltip {
    background-color: #0f1014;
    color: #999eab;
    border-radius: 2px;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #545454;
  }
}

.event-badge-preview {
  .custom-style {
    transform: none !important;
    position: unset !important;
    box-shadow:
      0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
}

.mdc-form-field > label {
  margin: 0 !important;
}

.mat-mdc-checkbox-touch-target {
  z-index: 1;
}

.custom-stepper {
  .mat-step-icon {
    margin-right: 25px !important;
    height: 40px;
    width: 40px;
    border: 1px solid #d9e2ff;

    .mat-step-icon-content {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .mat-step-label {
    width: 100% !important;
    border-top: 1px solid #d9e2ff !important;
    padding-top: 10px;
    top: 12px;
    position: absolute;
    left: 110px;
  }

  .mat-step-icon.mat-step-icon-state-done,
  .mat-step-icon.mat-step-icon-state-edit {
    background: #4fd1ba !important;
    color: #ffffff !important;
  }

  .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
    background: vars.$white !important;
    color: #091d50 !important;
  }

  .mat-step-icon.mat-step-icon-state-number {
    background: #eef0fe !important;
    color: #091d50 !important;
  }

  .mat-stepper-vertical-line::before {
    border-left-width: 3px;
    border-left-color: #dbe2fd !important;
    left: 5px !important;
    top: -16px !important;
  }

  .mat-stepper-vertical-line .mat-stepper-vertical-content {
    display: none;
  }

  .mat-step:nth-last-child(2) .mat-stepper-vertical-content-container {
    display: none;
    background-color: red;
  }

  .mat-step:nth-last-child(2) {
    .mat-stepper-vertical-line::before {
      display: none;
    }
  }

  .mat-step:last-child {
    display: none;
  }
}

.background-create-tab {
  .mdc-tab--active .mdc-tab__text-label {
    color: vars.$navy !important;
    letter-spacing: normal;
    opacity: 1 !important;
  }

  .mdc-tab .mdc-tab__text-label {
    font-weight: 700 !important;
    font-size: 18px !important;
    opacity: 0.4;
  }

  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border: none !important;
  }

  .mdc-tab--active:focus .mdc-tab-indicator__content--underline {
    border: none !important;
  }
}

.custom-circle-infinite-loader {
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;

  .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: vars.$ocean !important;
  }

  --mdc-circular-progress-active-indicator-color: #0c58c6 !important;
}

.custom-circle-infinite-loader-small {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;

  .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: vars.$ocean !important;
  }

  --mdc-circular-progress-active-indicator-color: #0c58c6 !important;
}

.add-button {
  .mdc-button__label {
    color: vars.$ocean;
  }
}

.border-radius {
  &-10 {
    --mdc-outlined-text-field-container-shape: 10px;
  }
}

.navy-checkbox {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #091d50;
  --mdc-checkbox-selected-hover-icon-color: #091d50;
  --mdc-checkbox-selected-icon-color: #091d50;
  --mdc-checkbox-selected-pressed-icon-color: #091d50;
  --mdc-checkbox-selected-focus-state-layer-color: #091d50;
  --mdc-checkbox-selected-hover-state-layer-color: #091d50;
  --mdc-checkbox-selected-pressed-state-layer-color: #091d50;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.message-receiver-select-panel {
  border-radius: 5px !important;

  .mat-mdc-option {
    padding: 0 8px;
    .mdc-list-item__primary-text {
      font-size: 12px;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;

      b {
        color: vars.$ocean;
      }
    }
  }
}

.cdk-overlay-pane:has(.message-receiver-select-panel) {
  width: 180px !important;
}

.mat-mdc-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: vars.$error !important;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch {
    border-color: vars.$error !important;
    border-width: 2.5px !important;
    opacity: 0.9;
  }

  .mdc-notched-outline__notch {
    border-left: none !important;
  }
}

/* Custom date rangepicker classes */
.mat-datepicker-content-container {
  height: 570px !important;

  .mat-calendar {
    width: 415px !important;

    .mat-calendar-content {
      .mat-calendar-table {
        .mat-calendar-body {
          .mat-calendar-body-label {
            padding-top: 5% !important;
            padding-bottom: 5% !important;
          }
        }
      }
    }
  }
}

.mat-datepicker-content-container-with-actions {
  .mat-datepicker-actions {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 15px;
  }

  .period-selector__button--active {
    color: vars.$navy !important;
  }

  .period-control__button::after {
    background-color: vars.$navy !important;
  }

  .ngx-timepicker-control--active::after {
    background-color: vars.$navy !important;
  }

  .ngx-timepicker-control {
    height: 35px !important;
    padding: 0px 5px !important;
  }
}

.item-editor-nav {
  .mat-drawer-inner-container {
    padding-bottom: 100px;
  }
}

@supports (scrollbar-color: auto) {
  .custom-scroll {
    scrollbar-color: #d9e2ff transparent;
    scrollbar-width: thin;
  }
}

@supports selector(::-webkit-scrollbar) {
  /* width */
  .custom-scroll::-webkit-scrollbar {
    width: 10px;
    border-radius: 25px;
  }
  /* Track */
  .custom-scroll::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 25px;
  }
  /* Handle */
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #d9e2ff;
    border-radius: 25px;
    :hover {
      background: vars.$grey-3e;
      border-radius: 25px;
    }
  }
}

.admin-summaries {
  ui-page-layout {
    .title {
      width: 100% !important;
    }
  }
}

.pop-up-menu {
  max-width: 300px !important;
  min-width: 250px !important;
  min-height: 130px !important;
  max-height: 230px !important;
  overflow-y: scroll;
  padding: 5px;
  border: 3px solid vars.$ocean;
}

.highlight {
  color: vars.$ocean !important; /* Change this to the desired highlight color */
  font-weight: bold !important;
}

.user-badge-data-menu {
  max-width: none !important;
  max-height: none !important;
  border-radius: 16px !important;
  overflow: hidden !important;
}
