@use 'mixins' as mixins;
@use 'variables' as vars;
@use 'media-queries' as mediaQueries;
@use 'theme' as theme;

html,
body {
  font-family: vars.$font-primary;
  --primary-color: #0c58c6;
  --secondary-color: #ad85eb;
  --button-text-color: #0c58c6;
  --button-background-color: rgba(255, 255, 255, 0.8);
  --background-color: #c1baff;
  --navi-color: #091d50;
  height: 100%;
}

.container {
  max-width: 1524px;
  margin: 0 auto;
}

.page-content {
  padding-bottom: 0px;
  position: relative;
  height: 100vh;

  @include mixins.break-point(tab) {
    min-height: inherit;
  }
}

.new-page-content {
  min-height: 660px;
}

.text-center {
  text-align: center;
}

.loading-container {
  position: fixed;
  z-index: 999;
  /* make higher than whatever is on the page */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .lds-ellipsis {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.ios,
.no-banner {
  header {
    @include mixins.break-point(tab) {
      top: 0 !important;
    }
  }

  .header-container {
    @include mixins.break-point(tab) {
      top: 0 !important;
    }
  }

  .back-link {
    @include mixins.break-point(tab) {
      top: 60px !important;
    }
  }

  .detail-container {
    @include mixins.break-point(tab) {
      padding-top: 0 !important;
    }
  }
}

// TBD: Added for Static UI only, needs to be removed
.inner-container {
  background-color: rgba(129, 183, 226, 0.8);
  height: 100%;
}

.hidden {
  display: none !important;
}

@include mixins.space('.m-r-', 'margin-right');
@include mixins.space('.m-l-', 'margin-left');
@include mixins.space('.m-t-', 'margin-top');
@include mixins.space('.m-b-', 'margin-bottom');
@include mixins.space('.m-', 'margin');

@include mixins.space('.p-r-', 'padding-right');
@include mixins.space('.p-l-', 'padding-left');
@include mixins.space('.p-t-', 'padding-top');
@include mixins.space('.p-b-', 'padding-bottom');
@include mixins.space('.p-', 'padding');

@include mixins.space('.border-', 'border-width');
@include mixins.space('.gap-', 'gap');

@include mixins.theming(theme.$theme...);

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-around {
  justify-content: space-around;
}

.uppercase {
  text-transform: uppercase;
}

.font-xs {
  font-size: 0.8rem !important;
}

.font-s {
  font-size: 0.9rem !important;
}

.font-m {
  font-size: 1rem !important;
}

.font-l {
  font-size: 1.3rem !important;
}

.font-xl {
  font-size: 1.6rem !important;
}

.font-2xl {
  font-size: 2rem !important;
}

.font-3xl {
  font-size: 3rem !important;
}

.letter-m {
  letter-spacing: 0.25px;
}

.letter-l {
  letter-spacing: 0.5px;
}

.weight-normal {
  font-family: vars.$font-primary !important;
}

.weight-bold {
  font-family: vars.$font-bold !important;
}

.weight-medium {
  font-family: vars.$font-medium !important;
}

.alignt-center {
  text-align: center;
}

.alignt-left {
  text-align: left;
}

.alignt-end {
  text-align: end;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.break-word {
  word-break: break-word;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.w-fit {
  width: fit-content !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vw !important;
}

.h-min {
  height: min-content !important;
}

.h-max {
  height: max-content !important;
}

.h-fit {
  height: fit-content !important;
}

@include mediaQueries.breakpoint(tablet) {
  .tablet-w-full {
    width: 100% !important;
  }

  .tablet-w-screen {
    width: 100vw !important;
  }

  .tablet-w-min {
    width: min-content !important;
  }

  .tablet-w-max {
    width: max-content !important;
  }

  .tablet-w-fit {
    width: fit-content !important;
  }

  .tablet-h-full {
    height: 100% !important;
  }

  .tablet-h-screen {
    height: 100vw !important;
  }

  .tablet-h-min {
    height: min-content !important;
  }

  .tablet-h-max {
    height: max-content !important;
  }

  .tablet-h-fit {
    height: fit-content !important;
  }
}

@include mediaQueries.breakpoint(mobile) {
  .mobile-w-full {
    width: 100% !important;
  }

  .mobile-w-screen {
    width: 100vw !important;
  }

  .mobile-w-min {
    width: min-content !important;
  }

  .mobile-w-max {
    width: max-content !important;
  }

  .mobile-w-fit {
    width: fit-content !important;
  }

  .mobile-h-full {
    height: 100% !important;
  }

  .mobile-h-screen {
    height: 100vw !important;
  }

  .mobile-h-min {
    height: min-content !important;
  }

  .mobile-h-max {
    height: max-content !important;
  }

  .mobile-h-fit {
    height: fit-content !important;
  }
}

.flex-1 {
  flex: 1 !important;
}

.border {
  border-style: solid;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.pointer {
  cursor: pointer;
}

.shadow-sm {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.shadow-md {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.shadow-lg {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.shadow-xl {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}

.underline {
  text-decoration: underline !important;
}

mat-form-field.mat-form-field-appearance-outline.welcome-custom-select-input {
  .mat-mdc-form-field-infix {
    padding: 0.7em 0 1em 0 !important;
    top: 0.4em;
  }

  .mat-mdc-option {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.25px;
  }

  .mat-mdc-select-value {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.25px;
  }
}

mat-form-field.mat-form-field-appearance-outline.welcome-custom-select-input.permission-denied-input {
  .mat-mdc-select-value {
    color: vars.$red-90 !important;
  }
}

.html-tooltip {
  position: relative;

  &-content {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 240px;
    padding: 10px 10px 10px 10px;
    margin: 0 0 30px -120px;
    border-radius: 0.5em;
    background-color: rgb(240, 240, 240);
    color: #222;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  &:hover {
    .html-tooltip-content {
      display: block;
    }
  }
  .tooltip-icon {
    filter: brightness(0);
  }
}

.tooltip {
  &-wide {
    max-width: unset !important;
  }
}

::ng-deep .no-ellipsis-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
  width: fit-content !important;
  max-width: unset !important;
}

.speaker-wrapper {
  .avatar-text {
    @include mediaQueries.breakpoint(mobile) {
      font-size: 20px !important;
    }
    @include mediaQueries.breakpoint(tablet) {
      font-size: 28px !important;
    }
    @include mediaQueries.breakpoint(desktop) {
      font-size: 36px !important;
    }
  }
}

// Class to replace fxHide
.hide-lt-md {
  @include mediaQueries.breakpoint(tablet-medium) {
    display: none;
  }
}

.hide-gt-sm {
  @include mediaQueries.breakpoint(desktop-small) {
    display: none;
  }
}
