@use 'sass:meta';
@use 'variables' as vars;

@mixin theming($args...) {
  @each $name, $color in meta.keywords($args) {
    .bg-color-#{$name} {
      background-color: $color !important;
    }
    .text-color-#{$name} {
      color: $color !important;
    }
    .stroke-#{$name} {
      stroke: $color !important;
    }
    .fill-#{$name} {
      fill: $color !important;
    }
    .border-#{$name} {
      border-color: $color !important;
    }
  }
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Breakpoints */
$mobile-bp: 480px;
@mixin break-point($point) {
  @if $point==mobile {
    @media only screen and (max-width: $mobile-bp) {
      @content;
    }
  }
}

$tab-bp: 768px;
@mixin break-point($point) {
  @if $point==tab {
    @media only screen and (max-width: $tab-bp) {
      @content;
    }
  }
}

$max: 64;
$offset: 2;
$unit: 'px';
@mixin space($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }
    $i: $i + $offset;
  }
}

@mixin flex-center-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Global CSS Styles for Material Components */

/* input fields */

input {
  color: vars.$grey-33 !important;
  caret-color: vars.$grey-33;
  font-family: vars.$font-primary;
  font-size: 14px;
}
