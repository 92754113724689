@use 'variables' as vars;

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
.loading-bar .mat-progress-bar-fill::after {
  background-color: vars.$cream !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
.loading-bar .mat-progress-bar-buffer {
  background-color: transparent !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
.loading-bar .mat-progress-bar-background {
  animation: none;
  background-color: transparent !important;
  fill: transparent !important;
}
