@use 'mixins' as mixins;
@use 'variables' as vars;

.button,
.standard-button {
  width: 290px;
  height: 40px;
  border-radius: 22px !important;
  font-family: vars.$font-primary;
  font-size: 14px !important;
  text-align: center !important;
  background: vars.$white;
  color: vars.$grey-33;
  box-shadow: none !important;
  outline: none !important;
  border: 0;
  &.dark-gray {
    background-color: vars.$grey-33 !important;
    color: vars.$white !important;
  }
  &:disabled {
    background-color: rgba(0, 0, 0, 0.1);
    color: vars.$grey-bd;
  }
}

.link-button {
  box-shadow: none !important;
  outline: none !important;
  color: vars.$grey-33;
  background-color: transparent;
  font-size: 14px !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: 0;
  padding: 5px 15px;
  font-family: vars.$font-bold !important;
  border-radius: 10px;
}

.mdc-button {
  font-family: Europa-Regular, sans-serif !important;
}
