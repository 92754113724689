/* You can add global styles to this file, and also import other style files */
@use 'fonts';
@use 'variables';
@use 'mixins' as mixins;
@use 'theme';
@use 'tab-container';
@use 'toastr';
@use '@angular/material/prebuilt-themes/deeppurple-amber.css';
@use 'buttons';
@use 'base';
@use 'dialog';
@use 'material';
@use 'media-queries';
@use 'ag-grid-community/styles/ag-grid.css';
@use 'ag-grid-community/styles/ag-theme-alpine.css';
@use 'progress-bar';
