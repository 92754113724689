@use 'sass:string';
@use 'sass:map';
@use 'sass:meta';
@use 'sass:list';

/* Media Queries */
$media_queries: (
  'desktop-extra-large': string.unquote('(min-width: 1600px) and (min-height:800px)'),
  'desktop-large': string.unquote('(min-width: 1440px)'),
  'desktop-medium': string.unquote('(min-width: 1245px)'),
  'desktop': string.unquote('(min-width: 1024px)'),
  'desktop-small': string.unquote('(min-width: 960px)'),
  'tablet': string.unquote('(min-width:430.1px) and (max-width:1023.9px)'),
  'tablet-medium': string.unquote('(max-width:959px)'),
  'tablet-toolbar-collapse-width': string.unquote('(max-width:1070px)'),
  'tablet-small': string.unquote('(min-width:430.1px) and (max-width:700px)'),
  'tablet-extra-small': string.unquote('(max-width:599px)'),
  'mobile-large': string.unquote('(min-width: 600px)'),
  'mobile-horizontal': string.unquote('(max-width:1023.9px) and (orientation:landscape)'),
  'not-mobile': string.unquote('(min-width:430.1px)'),
  'mobile': string.unquote('(max-width:430px)'),
  'mobile-small': string.unquote('(max-width:380px)'),
  'landscape': string.unquote('(orientation:landscape)'),
  'portrait': string.unquote('(orientation:portrait)'),
  'grid-md': string.unquote('(min-width:960px) and (max-width:1279px)'),
  'grid-lg': string.unquote('(min-width:1280px)'),
);

@mixin breakpoint($breakpoints) {
  $conditions: ();

  @each $breakpoint in $breakpoints {
    // If the key exists in the map
    $conditions: list.append($conditions, #{meta.inspect(map.get($media_queries, $breakpoint))}, comma);
  }

  @media #{$conditions} {
    @content;
  }
}

/* USAGE EXAMPLES
    //1)
    @include mediaQueries.breakpoint(desktop) {
        .laptop-red{ color:orangered }
    }
    //Compiles to:
    @media (min-width: 1024px) {
        .laptop-red {
            color: orangered;
        }
    }

    //2)
    @include mediaQueries.breakpoint(mobile tablet) {//between media query names use spaces only
        .test-red{ color:red }
    }
    //Compiles to:
    @media (max-width:430px), (min-width:430.1px) and (max-width:1023.9px) {
        .test-red {
            color: red;
        }
    }

    //3)
    .someClass {
        display:block;
        @include mediaQueries.breakpoint(desktop-large) {
            position:absolute;
        }
        @include mediaQueries.breakpoint(mobile) {
            position:relative;
        }
    }
    //compiles to:
    .someClass {
        display: block;
    }
    @media (min-width: 1440px) {
        .someClass {
            position: absolute;
        }
    }
    @media (max-width:430px) {
        .someClass {
            position: relative;
        }
    }

*/
