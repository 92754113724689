@use 'media-queries' as mediaQueries;
@use 'mixins' as mixins;
@use 'variables' as vars;

/* Global dialog substitute for ng-deep stylization */
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container.mat-mdc-dialog-container:has(.social-dialog) {
  position: relative;
  padding: 0;
}

.tutorial-custom-html {
  .map-arrow {
    .arrow {
      position: fixed;
      right: 102px;
      bottom: 70px;
    }
    .map-icon {
      position: fixed;
      right: 100px;
      bottom: 0;
    }
    @include mediaQueries.breakpoint(mobile tablet) {
      .arrow,
      .map-icon {
        display: none;
      }
    }
  }
  &.action-menu-is-open {
    .map-arrow {
      .arrow {
        position: fixed;
        right: 354px;
        bottom: 70px;
      }
      .map-icon {
        position: fixed;
        bottom: 0;
        right: 350px;
      }
      @include mediaQueries.breakpoint(mobile tablet) {
        .arrow,
        .map-icon {
          display: none;
        }
      }
    }
  }
}

/* Target specific attributes for Mozilla Firefox */
@supports (-moz-appearance: none) {
  .tutorial-custom-html {
    .map-arrow {
      .arrow {
        position: fixed;
        right: 95px;
        bottom: 70px;
      }
      .map-icon {
        position: fixed;
        right: 92px;
        bottom: 0;
      }
      @include mediaQueries.breakpoint(mobile tablet) {
        .arrow,
        .map-icon {
          display: none;
        }
      }
    }
    &.action-menu-is-open {
      .map-arrow {
        .arrow {
          position: fixed;
          right: 350px;
          bottom: 70px;
        }
        .map-icon {
          position: fixed;
          bottom: 0;
          right: 345px;
        }
        @include mediaQueries.breakpoint(mobile tablet) {
          .arrow,
          .map-icon {
            display: none;
          }
        }
      }
    }
  }
}

.social-dialog {
  padding: 1em;
  font-size: 16px;
  display: flex;
  justify-items: flex-start;
  flex-direction: column;
  gap: 1em;

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline,
  .social-dialog-input + span.mat-form-field-label-wrapper > label.mat-form-field-label {
    display: none;
  }

  .mat-mdc-form-field {
    width: calc(100% - 1em) !important;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper,
    .mat-form-field-infix {
      padding: 0;
      border-top: 0;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label-wrapper {
        top: 0;
        padding: 0;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    left: 0.66em;
    top: 1.33em;
  }
}

%social-dialog-font {
  font-family: vars.$font-primary;
  font-style: normal;

  &-bold {
    font-weight: 700;
  }
}

%social-dialog-flex {
  display: flex;

  &-column {
    flex-direction: column;
  }

  &-row {
    flex-direction: row;
  }

  &-gap {
    gap: 1em;
  }

  &-half-gap {
    gap: 0.5em;
  }

  &-center {
    justify-content: center;
    align-items: center;
  }

  &-start {
    justify-content: flex-start;
  }

  &-end {
    justify-content: flex-end;
  }
}

%social-dialog-margin-0 {
  margin: 0;
}

%social-dialog-full-width {
  width: 100%;
}

.social-dialog-title {
  @extend %social-dialog-font;
  @extend %social-dialog-font-bold;
  @extend %social-dialog-margin-0;
  @extend %social-dialog-full-width;

  font-size: 1.25em;
  color: vars.$navy;
  letter-spacing: 0.25px;
}

.social-dialog-content {
  @extend %social-dialog-flex;
  @extend %social-dialog-flex-column;
  @extend %social-dialog-flex-start;
  @extend %social-dialog-flex-gap;
  padding-top: 20px;
}

.social-dialog-dividing-line {
  @extend %social-dialog-margin-0;
  @extend %social-dialog-full-width;

  height: 1px;
  background-color: vars.$border;
}

.social-dialog-quick-links {
  @extend %social-dialog-font;
  @extend %social-dialog-font-bold;

  color: vars.$navy;
}

.social-dialog-links {
  @extend %social-dialog-flex;
  @extend %social-dialog-flex-row;
  @extend %social-dialog-flex-gap;
}

.social-dialog-link {
  @extend %social-dialog-font;
  @extend %social-dialog-flex;
  @extend %social-dialog-flex-row;
  @extend %social-dialog-flex-start;
  @extend %social-dialog-flex-half-gap;

  border-radius: 0.5em;
  padding: 0.25em 0.5em;
  color: vars.$ocean;
  background-color: vars.$border;
  border: 0;
}

.social-dialog-content-text {
  @extend %social-dialog-font;
  @extend %social-dialog-margin-0;
}

.social-dialog-footer {
  @extend %social-dialog-flex;
  @extend %social-dialog-flex-row;
  @extend %social-dialog-flex-end;
  @extend %social-dialog-flex-gap;
}

.social-dialog-form-container {
  @extend %social-dialog-margin-0;
}

.social-dialog-input {
  @extend %social-dialog-flex;
  @extend %social-dialog-flex-start;
  @extend %social-dialog-full-width;

  color: vars.$input-grey;
  border: 1px solid vars.$input-grey;
  padding: 0.25em 0.5em;
  border-radius: 10px;
  min-height: 40px;
}

.social-dialog-cancel {
  @extend %social-dialog-font;
  @extend %social-dialog-flex;
  @extend %social-dialog-flex-row;
  @extend %social-dialog-flex-center;

  padding: 0.375em 0.75em;
  color: vars.$ocean;
  border: 0;
  background-color: transparent;
}

.social-dialog-primary-button {
  @extend %social-dialog-font;
  @extend %social-dialog-font-bold;
  @extend %social-dialog-flex;
  @extend %social-dialog-flex-row;
  @extend %social-dialog-flex-center;

  padding: 0.375em 0.75em;
  color: vars.$white;
  border-radius: 25px;
  background-color: vars.$ocean;
  border: 0;
}
