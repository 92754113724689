/*color variables*/

$transparent-white-40: rgba(255, 255, 255, 0.4);

// Primary Colors
$ocean: #0c58c6;
$navy: #091d50;
$social-primary: $ocean;

// Accent Colors
$red: #ff4400;
$moss: #003c10;
$moss-light: #28695d;

// Neutral Colors
$peach: #ffc4c4;
$cream: #f3ede3;
$honey: #ffde9c;
$light-caramel: #ffe2bf;
$light-cream: #f3f6fd;

// Tonal Palette
$gold: #ffbd00;
$lilac: #cc9ced;

// Status Colors
$jellyfish: #4fd1ba;
$jellyfish-light: #a7e8dd;
$warning: #ff8a00;
$error: #d81435;
$orange-crayola: #ff5449;

// Black and White
$black: #000000;
$transparent-black-50: rgba(0, 0, 0, 0.5);
$white: #ffffff;
$white_f5: #f5f5f5;
$white_f6: #f6f6ff;
$white_f2: #f2f6ff;
$white_f0: #f0f0f0;
$white_f1: #f0f1f5;

// Pagination
$pagination-inactive: #3f5688;

// Primary Luminance
$luminance-99: #fbfcff;
$luminance-98: #f6f8ff;
$luminance-95: #eef0fe;
$luminance-90: #dbe2fd;
$luminance-80: #b4c5fa;
$luminance-70: #8ca8f9;
$luminance-60: #638cf5;
$luminance-50: #4672da;
$luminance-40: #2858c0;
$luminance-35: #1e4cac;
$luminance-30: #194195;
$luminance-25: #14367f;
$luminance-20: #0f2c6a;
$luminance-15: #0a2256;
$luminance-10: #061841;
$luminance-5: #030f2d;

// Color Variables still in use
// Not part of Design Doc
$tooltip-background: rgba(0, 0, 0, 0.7);
$plan-free: #f29676;
$plan-plus: #81b7e2;
$plan-premium: #eeb642;
$citrus: #eeb642;
$blue-grey: #6c737e;
$tangerine: #f29676;
$tangerine-soft: rgba(242, 150, 118, 0.2);
$mint: #39ad65;
$sea-foam: #2ea7a7;
$sky-blue: #81b7e2;
$light-indigo: #e4ebff;
$warning-red: #b00020;
$input-grey: #cfd5e7;
$dismissed-red: #900504;

// Scootaverse Variables
$border: #d9e2ff;
$ring-preview: #d9d9d9;

// Greens
$green: #4fd1ba;
$dark-green: #28695d;
$light-green: #a7e8dd;
$green-mint: rgba(57, 173, 101, 0.2);

// Greys
$grey-66: #666666;
$grey-ac: #adadac;
$grey-f2: #f2f2f2;
$grey-fb: #fbfbfb;
$grey-f9: #f9f9f9;
$grey-ef: #efefee;
$grey-d8: #d8d8d8;
$grey-de: #dedede;
$grey-25: #252525;
$grey-33: #333333;
$grey-3e: #3e3e3e;
$grey-7a: #7a7a7a;
$grey-e6: #e6e6e6;
$grey-bd: #bdbdbd;
$grey-ee: #eeeeee;
$grey-bc1: #bcbbc1;
$grey-f8: #f8f8f8;
$grey: #a1a1a1;
$grey-d1: #d1d1d6;
$grey-89: #898a8d;
$grey-7d: #7d7d7d;
$grey-80: #808080;

// Greys RGBAS
$grey-4d: rgba(217, 226, 255, 0.4);
$grey-8d: rgba(255, 255, 255, 0.8);
$grey-g3: rgba(173, 173, 172, 1);
$grey-01: rgba(255, 255, 255, 0.1);

// Black RGBAS
$black-25d: rgba(0, 0, 0, 0.25);

// White RGBAS
$white-2d: rgba(255, 255, 255, 0.2);
$white40: rgba(255, 255, 255, 0.4);
$white-8d: rgba(255, 255, 255, 0.8);

// Red RGBAS
$red: rgba(255, 84, 73, 1);
$red-90: rgba(144, 5, 4, 1);

/*font variables*/
$font-primary: 'Europa-Regular', sans-serif;
$font-bold: 'Europa-Bold', sans-serif;
$font-medium: 'Europa-Medium', sans-serif;
$font-light: 'Europa-Light', sans-serif;

$europa-regular-italic: 'Europa-RegularItalic', sans-serif;
$europa-bold-italic: 'Europa-BoldItalic', sans-serif;
$europa-light-italic: 'Europa-LightItalic', sans-serif;

$orleans-bold: 'Orleans-Bold', sans-serif;
$orleans-bold-italic: 'Orleans-BoldItalic', sans-serif;

$yellow-fc: #fcf0d9;
$yellow-a7: #a78234;
