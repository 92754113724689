/* Regular Light */
@font-face {
  font-family: 'CeraPRO-Light';
  src:
    local('CeraPRO-Light'),
    url('../assets/fonts/CeraPRO-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Regular Font */
@font-face {
  font-family: 'CeraPRO';
  src:
    local('CeraPRO'),
    url('../assets/fonts/CeraPRO-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Medium Font*/
@font-face {
  font-family: 'CeraPRO-Medium';
  src:
    local('CeraPRO-Medium'),
    url('../assets/fonts/CeraPRO-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Medium Bold*/
@font-face {
  font-family: 'CeraPRO-Bold';
  src:
    local('CeraPRO-Bold'),
    url('../assets/fonts/CeraPRO-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Europa-Regular';
  src:
    local('Europa-Regular'),
    url('../assets/fonts/Europa-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Europa-RegularItalic';
  src:
    local('Europa-RegularItalic'),
    url('../assets/fonts/Europa-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Europa-Bold';
  src:
    local('Europa-Bold'),
    url('../assets/fonts/Europa-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Europa-BoldItalic';
  src:
    local('Europa-BoldItalic'),
    url('../assets/fonts/Europa-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Europa-Medium';
  src:
    local('Europa-Medium'),
    url('../assets/fonts/Europa-Regular.otf') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Europa-Light';
  src:
    local('Europa-Light'),
    url('../assets/fonts/Europa-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Europa-LightItalic';
  src:
    local('Europa-LightItalic'),
    url('../assets/fonts/Europa-LightItalic.otf') format('opentype');
  font-weight: lighter;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Orleans-Bold';
  src:
    local('Orleans-Bold'),
    url('../assets/fonts/Orleans-Bold.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Orleans-BoldItalic';
  src:
    local('Orleans-BoldItalic'),
    url('../assets/fonts/Orleans-BoldItalic.otf') format('opentype');
  font-weight: lighter;
  font-style: italic;
  font-display: swap;
}
