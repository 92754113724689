@use 'mixins' as mixins;
@use 'variables' as vars;

.tabs-container {
  background: vars.$white;
  max-height: calc(100vh - 80px);
  min-height: 1150px;
  width: 600px;
  position: relative;
  z-index: 11;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 0px;
  @include mixins.break-point(tab) {
    max-height: 100%;
    min-height: 0;
  }

  @include mixins.break-point(tab) {
    width: 100%;
    z-index: 1;
  }
  .error-page-container {
    font-size: 0.5em;
  }
}

.app-content {
  display: flex;
  justify-content: flex-end;
}

.tab-footer {
  text-align: center;
  position: absolute;
  padding: 33px 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid vars.$grey-ee;
  background: vars.$white;
  @include mixins.break-point(tab) {
    display: none;
  }
}

.logo-wrapper {
  display: inline-block;
  width: 150px;
  > img {
    max-width: 100%;
  }
}

.tabs-header {
  display: flex;
  background: vars.$grey-f9;

  > a {
    flex: 1;
    text-decoration: none;
    text-align: center;
    font-size: 12px;
    padding: 20px 10px 10px;
    text-transform: uppercase;
    border-bottom: 4px solid vars.$grey-ef;
    color: vars.$grey-ac;
  }
}
